import { isAction } from './isAction';

export default class GameEngine {
    // Constructor props
    started;
    position;
    currentNode;
    gameData;
    addSystemMessage;
    setMessages;
    setCommandHistory;
    enableInput;
    disableInput;
    setActiveNode;
    setGameVar;
    setGameVarStore;
    addInventoryItem;
    removeInventoryItem;
    setGameInventoryStore;
    localVars;
    localInventory;
    delayOverride;

    constructor({
        gameData,
        addSystemMessage,
        setMessages,
        setCommandHistory,
        enableInput,
        disableInput,
        setActiveNode,
        setGameVar,
        setGameVarStore,
        addInventoryItem,
        removeInventoryItem,
        setGameInventoryStore,
        delayOverride,
    }) {
        this.started = false;
        this.position = null;
        this.currentNode = null;
        this.gameData = gameData;
        this.addSystemMessage = addSystemMessage;
        this.setMessages = setMessages;
        this.setCommandHistory = setCommandHistory;
        this.setActiveNode = setActiveNode;
        this.delayOverride = delayOverride;
        this.enableInput = enableInput;
        this.disableInput = disableInput;
        this.setGameVar = setGameVar;
        this.setGameVarStore = setGameVarStore;
        this.addInventoryItem = addInventoryItem;
        this.removeInventoryItem = removeInventoryItem;
        this.setGameInventoryStore = setGameInventoryStore;
        this.localVars = {};
        this.localInventory = [];
        // console.log('GameEngine class instantiated.');

        if (process.env.NODE_ENV) {
            if (this.verifyUniqueIds()) console.log('Game data is validated.');
        }
    }

    reset() {
        if (this.started === true) {
            this.disableInput();
            this.setMessages([]);
            this.setCommandHistory([]);
            this.setActiveNode(undefined);
            this.setGameVarStore({});
            this.setGameInventoryStore([]);
            this.localVars = {};
            this.localInventory = [];
            this.position = null;
            this.currentNode = null;
            console.log('Game reset.');
        }
    }

    setVar(key, val = true) {
        if (typeof val === 'undefined') val = true;
        this.localVars[key] = val;
        this.setGameVar(key, val);
        // console.log('Set var "' + key + '" to "' + JSON.stringify(val) + '".', this.localVars);
    }

    getVar(key) {
        const localVar = this.localVars[key];
        if (localVar === undefined) return false;
        return localVar;
    }

    addItem(itemName) {
        if (!this.localInventory.includes(itemName)) {
            this.localInventory.push(itemName);
            this.addInventoryItem(itemName);
            // console.log('Adding item "' + itemName + '" to inventory.', this.localInventory);
        }
        // else console.log('Item "' + itemName + '" already in inventory.', this.localInventory);
    }

    removeItem(itemName) {
        if (this.localInventory.includes(itemName)) {
            this.localInventory = this.localInventory.filter((i) => i !== itemName);
            this.addInventoryItem(itemName);
            // console.log('Removed item "' + itemName + '" from inventory.', this.localInventory);
        }
        // else console.log('Item "' + itemName + '" not in inventory.', this.localInventory);
    }

    hasItems(items) {
        return items.filter((i) => this.localInventory.includes(i)).length === items.length;
    }

    hasItem(item) {
        return this.hasItems([item]);
    }

    startGame() {
        this.reset();
        this.started = true;
        this.activateNode(this.gameData[0]);
        console.log('Game started.');
    }

    sendOutput(node) {
        return new Promise((resolve) => {
            if (node.output) {
                // Check for message delay
                let addMsgDelay = 0;
                if (node.delay) {
                    if (this.delayOverride) {
                        addMsgDelay = this.delayOverride;
                    } else if (node.delay === true) {
                        addMsgDelay = 2000;
                    } else {
                        addMsgDelay = node.delay;
                    }
                }

                setTimeout(() => {
                    this.addSystemMessage(node.output, node.outputType);
                    resolve();
                }, addMsgDelay);
            } else {
                resolve();
            }
        });
    }

    verifyUniqueIds(nodes = null, idSet = new Set()) {
        if (nodes === null) nodes = this.gameData;
        for (let n of nodes) {
            // If the id already exists in the set, it's a duplicate
            if (idSet.has(n.id)) {
                console.error('Duplicate ID found in game data: ' + n.id);
                return false;
            }
            // Add the id to the set
            idSet.add(n.id);

            // If the node has actions, recursively check their ids
            if (n.actions && n.actions.length > 0) {
                const isUnique = this.verifyUniqueIds(n.actions, idSet);
                if (!isUnique) {
                    return false;
                }
            }
        }
        return true;
    }

    findNodeById(id, nodes = null) {
        if (nodes === null) nodes = this.gameData;
        for (let n of nodes) {
            if (n.id === id) {
                return n;
            }
            if (n.actions && n.actions.length > 0) {
                const result = this.findNodeById(id, n.actions);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    }

    activateWarpNode(node) {
        const warpNode = this.findNodeById(node.warpTo);
        console.log('.----=~~~`\\: WARP! :/`~~~=----.', warpNode);
        if (node.silent === true) {
            // Silenty warp to the node and enable input
            this.currentNode = warpNode;
            this.enableInput();
        } else {
            this.activateNode(warpNode);
        }
    }

    activationFollowUp(node) {
        if (node.types[0] === 'warp') {
            // Warp to a specific node (and enable input)
            return this.activateWarpNode(node);
        }

        if (node.actions && node.actions.length > 0) {
            for (let i = 0; i < node.actions.length; i++) {
                const action = node.actions[i];

                // Check for auto actions
                if (action.types[0] === 'auto' || action.types[0] === 'warp') {
                    if (this.actionReqsAreMet(action)) {
                        // Auto-advance to next node
                        return this.activateNode(action);
                    }
                }
            }

            // No fancy node, just enable input
            this.enableInput();
        }
    }

    activateNode(node) {
        if (!node) {
            return;
        }

        // Set var
        if (node.setVar) {
            let key = typeof node.setVar === 'object' ? node.setVar[0] : node.setVar;
            let val = typeof node.setVar === 'object' && node.setVar.length > 1 ? node.setVar[1] : true;
            if (key[0] === '!') {
                key = key.slice(1);
                val = false;
            }
            this.setVar(key, val);
        }

        // Add item
        if (node.addItem) {
            let itemName = node.addItem;
            this.addItem(itemName);
        }

        // Add item
        if (node.removeItem) {
            let itemName = node.removeItem;
            this.removeItem(itemName);
        }

        // Let parent (Game) know about the active node
        this.setActiveNode(node);

        // Add the message (with or without delay)
        this.sendOutput(node).then(() => {
            this.activationFollowUp(node);
        });

        // Detect game over
        if (node.gameOver === true) {
            this.sendOutput({
                output: 'Game Over',
                outputType: 'center',
                delay: 1000,
            });
        } else {
            // If this action has children, set as current node
            // However, sometimes there is only one or two auto nodes with requirements.
            // Let's be sure there is an action with requirements met.
            const hasPermittedActions = node.actions?.some((a) => this.actionReqsAreMet(a)) ?? false;
            if (hasPermittedActions) {
                this.currentNode = node;
            } else {
                // If current node has multiple actions, allow for other commands
                if (this.currentNode.actions.length > 1) {
                    this.enableInput();
                }
            }
        }
    }

    actionReqsAreMet(action) {
        const actionReqVars = action.reqVars ?? [];
        const actionReqItems = action.reqItems ?? [];

        // Check if has required var(s)
        const requiredVarsFound = actionReqVars.filter((v) => {
            let defaultValue = true;
            let key = typeof v === 'object' ? v.key : v;
            if (key[0] === '!') {
                key = key.slice(1);
                defaultValue = false;
            }
            let val = typeof v === 'object' ? v.value : defaultValue;
            // if (this.getVar(key) === val) console.log('VAR VALUE MATCH: ' + key, val);
            // else console.warn('VAR VALUE DOES NOT MATCH: ' + key, val);
            return this.getVar(key) === val;
        });
        if (requiredVarsFound.length < actionReqVars.length) {
            return false;
        }

        // Check if has required item(s)
        const requiredItemsFound = actionReqItems.filter((i) => {
            if (i[0] === '!') {
                i = i.slice(1);
                return !this.hasItem(i);
            }
            // if (this.hasItem(i)) console.log('HAS NEEDED ITEM: ' + i);
            // else console.warn('DOES NOT HAVE NEEDED ITEM: ' + i);
            return this.hasItem(i);
        });
        if (requiredItemsFound.length < actionReqItems.length) {
            return false;
        }

        return true;
    }

    processCommand(inputCommand) {
        this.disableInput();
        // Sort the actions so that actions with target(s) are sorted before others.
        // Then find a valid action that matches the inputCommand.
        const matchedAction = this.currentNode.actions
            .sort((a, b) => ('targets' in b) - ('targets' in a))
            .find((action) => {
                return action.types.some((actionType) => {
                    // Check if the command input matches the action
                    // console.log(`Checking if command "${inputCommand}" matches "${actionType}" action type.`, action);
                    if (isAction({ action, actionType, inputCommand })) {
                        // Make sure the action requirements are met
                        if (!this.actionReqsAreMet(action)) {
                            return false; // continue
                        }

                        // Save command for input types
                        if (actionType === 'input') this.setVar(action.varName, inputCommand);

                        // Activate the node
                        this.activateNode(action);

                        return true; // break
                    }
                    return false; // continue
                });
            });

        if (!matchedAction) {
            this.invalidCommand();
        }

        return matchedAction;
    }

    invalidCommand() {
        const noMatchMsgOptions = [
            'Nothing seemed to happen.',
            'Perhaps you should try something different.',
            'Nothing happened. Try something else.',
            'That did nothing.',
            'Try something else, perhaps.',
            'Maybe you should do something else.',
            'Hmmm... that did nothing.',
        ];

        const randomIndex = Math.floor(Math.random() * noMatchMsgOptions.length);
        const noMatchNode = JSON.parse(JSON.stringify(this.currentNode)); // Not sure if this is necessary
        noMatchNode.output = noMatchMsgOptions[randomIndex];
        noMatchNode.outputType = 'narrative';
        noMatchNode.delay = false;
        this.sendOutput(noMatchNode);
        this.enableInput();
    }
}
